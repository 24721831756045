var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageHeader', {
    attrs: {
      "title": "Edit Portfolio",
      "sub-title": _vm.assetGroup.name
    }
  }), _c('div', {
    staticClass: "content content-full content-boxed"
  }, [_vm.errorAction.update ? _c('div', {
    staticClass: "alert alert-danger"
  }, [_vm._v(_vm._s(_vm.errorAction.update.message))]) : _vm._e(), _c('div', {
    staticClass: "block block-rounded"
  }, [_c('div', {
    staticClass: "block-content"
  }, [_c('h3', [_vm._v("Edit Portfolio")]), _c('PortfolioForm', {
    attrs: {
      "id": _vm.assetGroup._id,
      "error": _vm.validationErrors
    },
    model: {
      value: _vm.form,
      callback: function callback($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }), _c('div', {
    staticClass: "text-right pb-4"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "submit",
      "disabled": _vm.loadingAction.update
    },
    on: {
      "click": _vm.onUpdate
    }
  }, [_vm.loadingAction.update ? _c('span', [_c('i', {
    staticClass: "fa fa-spin fa-spinner mr-1"
  }), _vm._v(" Updating...")]) : _c('span', [_c('i', {
    staticClass: "fa fa-check-circle mr-1"
  }), _vm._v(" Update Portfolio")])])])], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }