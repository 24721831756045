<template>
  <div>
    <PageHeader title="Edit Portfolio" :sub-title="assetGroup.name" />
    <div class="content content-full content-boxed">
      <div v-if="errorAction.update" class="alert alert-danger">{{ errorAction.update.message }}</div>
      <div class="block block-rounded">
        <div class="block-content">
          <h3>Edit Portfolio</h3>
          <PortfolioForm :id="assetGroup._id" v-model="form" :error="validationErrors" />
          <div class="text-right pb-4">
            <button type="submit" class="btn btn-primary" :disabled="loadingAction.update" @click="onUpdate">
              <span v-if="loadingAction.update"><i class="fa fa-spin fa-spinner mr-1"></i> Updating...</span>
              <span v-else><i class="fa fa-check-circle mr-1"></i> Update Portfolio</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import PageHeader from '@/components/PageHeader';
import PortfolioForm from '@/components/forms/PortfolioForm';

export default {
  name: 'PortfolioEdit',
  components: {
    PageHeader,
    PortfolioForm
  },
  data() {
    return {
      form: {
        name: '',
        type: 'custom',
        companyId: '',
        entityId: '',
        userSubs: [],
        userSub: ''
      },
      selectedAsset: ''
    };
  },
  computed: {
    ...mapGetters({
      assetGroup: 'assetGroup/assetGroup',
      loadingAction: 'assetGroup/loadingAction',
      errorAction: 'assetGroup/errorAction',
      validationErrors: 'assetGroup/validationErrors',
      assets: 'assetGroup/assets'
    })
  },
  async mounted() {
    await this.get({ id: this.$route.params.id });

    this.form = {
      ...this.assetGroup
    };

    if (!this.assetGroup.userSub) {
      this.form.userSub = this.$auth.user.sub;
    }
  },
  methods: {
    ...mapActions({
      get: 'assetGroup/get',
      update: 'assetGroup/update'
    }),
    async onUpdate() {
      await this.update({ id: this.$route.params.id, data: this.form });

      if (Object.keys(this.validationErrors).length > 0) return;
      this.$toasted.success('Updated successfully!', { position: 'bottom-right', duration: 3000 });
    }
  }
};
</script>
